import ResponsePage, {
  ResponsePageType,
} from "../component/common/ResponsePage";
import * as React from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import HeaderLayout from "./HeaderLayout";
import {currentApiDomain} from "../util/helperUtil";
import {useTranslator} from "../localization/Translator";

export default function Logout() {

  const t = useTranslator();
  return (
    <header>
      <SpaceBetween size={"xxl"}>
        <HeaderLayout />
        <ResponsePage
          message={t.translate("logout_message")}
          buttonName={t.translate("login_button_name")}
          buttonOnClick={() => window.location.replace(currentApiDomain + "/Authorize")}
          headerTitle={t.translate("logout_title")}
          pageType={ResponsePageType.INFORMATION}
        />
      </SpaceBetween>
    </header>
  );
}
