import * as React from "react";
import Notification, {NotificationType} from "./Notification";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";
import {currentApiDomain} from "../../util/helperUtil";
import {CREATE_NEW_TEAM_URL} from "../../module/AuthAdminModule";
import {Header} from "@amzn/awsui-components-react";
import {useTranslator} from "../../localization/Translator";

export interface ErrorNotificationProps {
  errorCode: string;
  data?: string;
}

const ErrorNotification = (props: ErrorNotificationProps) => {
  const t = useTranslator();
  const AUTHORIZE_URL = `${currentApiDomain}/Authorize`;
  let notificationType = NotificationType.ERROR;
  const getErrorMessage = (errorCode: string): any => {
    switch (errorCode) {
      case "40301":
        return t.translate("forbidden_error_message");
      case "40303":
        return t.translate("forbidden_error_message_site_report");
      case "40101":
      case "40102":
      case "40107":
        return t.translate("unauthorized_error_message");

      case "40003":
        return (
            <Trans>
              {t.translate("invalid_input_group")}
              <Link to={CREATE_NEW_TEAM_URL}>{t.translate("form_field_link_text")}</Link>
            </Trans>
        );

      case "40006":
        return t.translate("group_already_assigned");
      case "40104":
      case "40001":
      case "40002":
      case "40004":
      case "40005":
        return (
            <Trans>
              {t.translate("invalid_input_error_message")}
              <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
            </Trans>
        );
      case "40400":
        return (
            <Trans>

              {t.translate("badge_not_found_message", {data : props.data})}
              <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
            </Trans>
        );
      case "40406":
        return (
            <Trans>
              {t.translate("unregistered_badge_found_notifications_message")}
              <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
            </Trans>
        );
      case "40407":
        return t.translate("disabled_badge_found_notifications_message");
      case "40302":
        return (
            <Trans>
              {t.translate("forbidden_admin_operation_message")}
              <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
            </Trans>
        );
      case "40105":
        return (
            <Trans>
              {t.translate("badge_details_retrieval_failed_message")}
              <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
            </Trans>
        );
      case "40106":
        return (
          <Trans>
            {t.translate("generate_pin_session_invalid_different_alias")}
            <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
          </Trans>
        );
      case "50001":
        return (
            <Trans>
              {t.translate("internal_server_error_message_one")}
              <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
            </Trans>
        );
      case "20005":
      case "50002":
        return (
            <Trans>
              {t.translate("internal_server_error_message_two")}
              <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
            </Trans>
        );

      default:
        return (
            <Trans>
              {t.translate("un_expected_error_try_again")}
              <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
            </Trans>
        );
    }
  };

  const getErrorHeader = (errorCode: string) => {
    switch (errorCode) {
      case "40400":
        notificationType = NotificationType.WARNING;
        return (
            <React.Fragment>
              <Header variant="h2">{t.translate("badge_not_found_header")}</Header>
            </React.Fragment>
        );
      case "40302":
        notificationType = NotificationType.WARNING;
        return (
            <React.Fragment>
              <Header variant="h2">{t.translate("forbidden_admin_operation_header")}</Header>
            </React.Fragment>
        );
      case "40406":
        notificationType = NotificationType.WARNING;
        return (
            <React.Fragment>
              <Header variant="h2">{t.translate("unregistered_badge_found_notifications_header")}</Header>
            </React.Fragment>
        );
      case "40407":
        notificationType = NotificationType.ERROR;
        return (
            <React.Fragment>
              <Header variant="h2">{t.translate("disabled_badge_found_notifications_header")}</Header>
            </React.Fragment>
        );
      default:
        return t.translate("server_error_code", {errorId: props.errorCode});
    }
  }

  const message = getErrorMessage(props.errorCode);
  const header = getErrorHeader(props.errorCode);

  return (
    <Notification
      notificationType={notificationType}
      message={message}
      header={header}
    />
  );
};

export default ErrorNotification;
