import Button from "@amzn/awsui-components-react/polaris/button";
import { SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import * as React from "react";
import { EventHandler } from "react";

interface FormActionsProps {
  submitOnClick?: EventHandler<any>;
  cancelOnClick?: EventHandler<any>;
  cancelButtonName: string;
  submitButtonName: string;
  isLoading?: boolean;
  isInputInvalid?: boolean;
}

const FormActions = (props: FormActionsProps) => {
  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button
        formAction="none"
        variant="link"
        disabled={props.isLoading}
        onClick={props.cancelOnClick}
      >
        {props.cancelButtonName}
      </Button>
      <Button
        variant="primary"
        onClick={props.submitOnClick}
        disabled={props.isLoading || props.isInputInvalid}
      >
        {props.isLoading ? <Spinner /> : props.submitButtonName}
      </Button>
    </SpaceBetween>
  );
};

export default FormActions;
