import i18next from "i18next";
import {ButtonDropdownProps} from "@amzn/awsui-components-react";
import {CancelableEventHandler} from "@amzn/awsui-components-react/uxdg/internal/events";
import locales from "./locales.json";

interface LanguageOption {
  id: string;
  text: string;
}

export const getSupportedLanguageOptions = (): LanguageOption[] => {
  const supportedLocales = locales.supportedLocales;
  const languageOptions: LanguageOption[] = [];

  for (const locale in supportedLocales) {
    if (supportedLocales.hasOwnProperty(locale)) {
      let langName = getDisplayText(locale);
      languageOptions.push({ id: locale, text: langName });
    }
  }

  return languageOptions;
};

const getDisplayText = (langId: string) => {
  // @ts-ignore
  let locale  = locales.supportedLocales[langId];
  let displayText = locale.langName + ' (' + locale.countryName + ')';
  return displayText;
}

export const defaultLocale :string = "en-US";
export const userLanguagePref :string = "userLangPref";
export const setLanguage: CancelableEventHandler<ButtonDropdownProps.ItemClickDetails> = (event) => {
  setLocale(event.detail.id);
}

// Function to set the language based on the provided locale
export const setLocale = (localeProvided: string | null) => {
  const userPrefferedLocale = localStorage.getItem(userLanguagePref);
  if (localeProvided && localeProvided.trim() !== "") {
    if (locales.supportedLocales.hasOwnProperty(localeProvided)) {
      localStorage.setItem(userLanguagePref, localeProvided);
      i18next.changeLanguage(localeProvided);
      return;
    }
  } else if (userPrefferedLocale && userPrefferedLocale.trim() !== "") {
    if (locales.supportedLocales.hasOwnProperty(userPrefferedLocale)) {
      i18next.changeLanguage(userPrefferedLocale);
      return
    }
  }
  localStorage.setItem(userLanguagePref, defaultLocale);
  // Fallback to default locale if no matching locale is provided or supported
  i18next.changeLanguage(defaultLocale);
};