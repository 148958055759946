import * as React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {getServiceNameFromLocation} from '../../util/helperUtil';
import SelectPage from '../common/SelectPage';
import {SpaceBetween} from "@amzn/awsui-components-react";
import DescriptionBox from "../common/DescriptionBox";
import {useTranslator} from "../../localization/Translator";

const Operation = () => {
  const t = useTranslator();;
  const location = useLocation();
  const navigate = useNavigate();
  const operationsString = localStorage.getItem("operations") || JSON.stringify([""]);
  const operations: string[] = JSON.parse(operationsString);
  const serviceName = getServiceNameFromLocation(location);
  const navigateToSite = (operation: string) => {
    navigate(operation);
  }

  const getButtonName = (operation: string) => {
    return t.translate(operation);
  }

  return (
      <SpaceBetween size={"xs"} alignItems="center">
        <DescriptionBox operations={operations} serviceName={serviceName} />
        <SelectPage
            headerTitle={t.translate("operation_component_header", {
              // To update the service name with localization if needed in the future.
              serviceName: serviceName.toUpperCase()
            })}
            data={operations}
            buttonOnClick={navigateToSite}
            buttonNameFn={getButtonName}/>
      </SpaceBetween>
  );
}
export default Operation;