import {useLocation} from 'react-router-dom';
import SiteIdForm from './SiteIdForm';
import TeamIdForm from './TeamIdForm';
import * as React from 'react';

const CreateSiteAdmin = () => {
    const {state} = useLocation();
    return (
        state && state.siteId && state.siteId.length !== 0 ? <TeamIdForm/> : <SiteIdForm/>
    );
}

export default CreateSiteAdmin;