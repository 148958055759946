import * as React from 'react';
import {Trans} from 'react-i18next';
import {SpaceBetween} from '@amzn/awsui-components-react';
import {Link} from 'react-router-dom';

interface MessageBoxProperty {
  message: string,
  orderedList?: string[],
  linkText?: string,
  link?: string
}

const ConfirmationMessage = (props: MessageBoxProperty) => {
  return (<><SpaceBetween size={'xxxs'}>
      <Trans>{props.message} <Link to={props.link || "#"}>{props.linkText}</Link></Trans>
      <ol>
        {props.orderedList?.map((arg, index) => (
          <li key={index}>
            <Trans> {arg} </Trans>
          </li>
        ))}
      </ol>
    </SpaceBetween>
    </>
  )
};

export default ConfirmationMessage;