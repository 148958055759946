import ResponsePage, {
  ResponsePageType,
} from "../component/common/ResponsePage";
import * as React from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import HeaderLayout from "./HeaderLayout";
import {useTranslator} from "../localization/Translator";

const SITE_ONBOARDING_LINK =
    "https://w.amazon.com/bin/view/CLS/SoftwareDevelopment/CID/Project/AdminPortal/OPGuide/SiteAdminCreation";

export default function NotAuthorizedLayout() {
  const t = useTranslator();
  return (
      <header>
        <SpaceBetween size={"xxl"}>
          <HeaderLayout />
          <ResponsePage
              message={t.translate("not_authorized_message")}
              linkText={ t.translate("guide_link_text")}
              link={SITE_ONBOARDING_LINK}
              headerTitle={t.translate("not_authorized_title")}
              pageType={ResponsePageType.INFORMATION}
          />
        </SpaceBetween>
      </header>
  );
}
