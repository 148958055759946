import * as React from 'react';
import {Trans} from 'react-i18next';
import {Alert} from '@amzn/awsui-components-react';
import {useTranslator} from "../../localization/Translator";


interface DescriptionBoxProps {
    serviceName: string
    operations: string[]
}

const DescriptionBox = (props: DescriptionBoxProps) => {
    const t = useTranslator();

    return (
        <div style={{ marginBottom: 30}}>
            <Alert statusIconAriaLabel="Info"
                   type="info"
                   header={t.translate("welcome_admin_header", {serviceName: props.serviceName})}
            >
                <Trans>
                    <ul style={{ paddingLeft: 20, marginTop: 5, marginBottom: 0}}>
                        {props.operations.map((operation, index) => {
                          return <li key={index}>{t.translate(operation + "_operation_description")}</li>
                        })}
                    </ul>
                </Trans>
            </Alert>
        </div>

    )
}

export default DescriptionBox;