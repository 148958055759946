import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { TopNavigationProps } from "@amzn/awsui-components-react/uxdg";
import { HOME_PAGE_URL } from "../module/AuthAdminModule";
import {currentApiDomain} from "../util/helperUtil";
import locales from "../localization/locales.json"
import i18n from "i18next";
import {getSupportedLanguageOptions, setLanguage} from "../localization/LocalizationModule";
import {useTranslator} from "../localization/Translator";

interface HeaderLayoutProps {
  userId?: string;
}

const HeaderLayout = (props: HeaderLayoutProps) => {
  const t = useTranslator();
  const signOutText = t.translate("auth_admin_portal_sign_out_text");
  const redirectToLogout = () => {
    fetch(currentApiDomain + '/Logout', {
      method: 'GET', mode: 'cors', credentials: 'include', headers: {
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    }).then(response => response.json()).then(function (res) {
      window.location.replace(window.origin + "/logout");
    });
  }
  const userProfileDropDown: TopNavigationProps.Utility = {
    type: "menu-dropdown",
    text: props.userId,
    iconName: "user-profile",
    items: [{ id: "signout", text: signOutText }],
    onItemClick: redirectToLogout
  };
  const localeDropdown : TopNavigationProps.Utility = {
    type: "menu-dropdown",
    // @ts-ignore
    text: locales.supportedLocales[i18n.language].langName,
    items: getSupportedLanguageOptions(),
    // @ts-ignore
    iconName: "flag",
    onItemClick: setLanguage
  }

  return (
    <TopNavigation
      id="h"
      identity={{
        href: HOME_PAGE_URL,
        title: t.translate("auth_admin_portal_title"),
      }}
      utilities={[localeDropdown, userProfileDropDown]}
    />
  );
};
export default HeaderLayout;
