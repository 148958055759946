import * as React from 'react';
import {
    GetAssociateDetailsResponse,
    INTERNAL_SERVER_ERROR_CODE_TWO,
    SessionDetails, BADGE_NOT_ACTIVE
} from "../../../module/AuthAdminModule";
import {SpaceBetween} from "@amzn/awsui-components-react";
import {useEffect, useState} from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {currentApiDomain, getTimeZoneAndFormat} from "../../../util/helperUtil";
import {useOutletContext} from "react-router-dom";
import ShowTempPin from "./ShowTempPin";
import ErrorNotification from "../../common/ErrorNotification";
import DisplayAssociateDetails from "../../common/DisplayAssociateDetails";
import { useTranslator } from '../../../localization/Translator';


interface PinResetRequestPageProps {
    data: GetAssociateDetailsResponse,
    serviceName: string,
    associateAlias: string
}

const PinResetRequestPage = (props: PinResetRequestPageProps) => {
    const GENERATE_TEMP_PIN_API = `${currentApiDomain}/GenerateTempPin`;
    const THIRTY_MINUTES_IN_SECONDS = 30 * 60000;
    const [tempPin, setTempPin] = useState("");
    const [tempPinExpiry, setTempPinExpiry] = useState("");
    const sessionDetails: SessionDetails = useOutletContext();
    const t = useTranslator();
    const [errorCode, setErrorCode] = useState<string | null>(null);

    useEffect(() => {
        if (props && props.data != null && props.data.employeeBadgeStatus !== "ACTIVE") {
            setErrorCode(BADGE_NOT_ACTIVE);
        }
    }, [props, props.data]);

    const requestPinResetHandler = () => {
        fetch(GENERATE_TEMP_PIN_API, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "anti-csrftoken-a2z": sessionDetails.csrfToken,
            },
            body: JSON.stringify({
                serviceName: props.serviceName,
                associateAlias: props.associateAlias,
            }),
        }).then(response => {
            if (response.status === 401) {
                // redirect to authenticate
                window.location.replace(currentApiDomain + "/Authorize");
            }
            return response.json();
        }).then(responseBody => {
            if (responseBody.success) {
                setTempPin(responseBody.tempPin);
                setTempPinExpiry(new Date(new Date().getTime() + THIRTY_MINUTES_IN_SECONDS).toLocaleString() + getTimeZoneAndFormat(new Date()));
            } else {
                if (responseBody.responseCode !== null) {
                    setErrorCode(responseBody.responseCode);
                } else if (responseBody.errorId !== null) {
                    setErrorCode(responseBody.errorId);
                } else {
                    setErrorCode(INTERNAL_SERVER_ERROR_CODE_TWO);
                }
            }
        }).catch(error => {
            console.error(error.message);
            setErrorCode(INTERNAL_SERVER_ERROR_CODE_TWO);
        });
    }

    return (
        <div style={{width: "80%", margin: "0 auto"}}>
            <SpaceBetween direction="vertical" size="l">
                <DisplayAssociateDetails data={props.data} />
                {tempPin.length === 0 && !errorCode && <Button disabled={props.data.employeeBadgeStatus?.toUpperCase() !== "ACTIVE"}
                 variant="primary" onClick={requestPinResetHandler}>{t.translate("request_pin_reset_button")}</Button>}
                {tempPin.length > 0 && <ShowTempPin tempPin={tempPin} tempPinExpiry={tempPinExpiry}/> }
                {errorCode && <ErrorNotification errorCode={errorCode} />}
                {
                    (tempPin.length > 0 || errorCode) &&
                    <Button variant="primary" onClick={() => window.location.replace(currentApiDomain + "/Authorize")}>
                        {t.translate("return_to_home_page_button_name")}
                    </Button>
                }
            </SpaceBetween>
        </div>
    )
}

export default PinResetRequestPage;