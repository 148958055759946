import * as React from "react";
import {NavigateFunction, useNavigate, useOutletContext} from "react-router-dom";
import {ServiceAccessInfo, SessionDetails} from "../../module/AuthAdminModule";
import SelectPage from "../common/SelectPage";
import { capitalizeFirstLetter } from "../../util/helperUtil";
import { SpaceBetween } from "@amzn/awsui-components-react";
import {useTranslator} from "../../localization/Translator";

const Service = () => {
  const navigate: NavigateFunction = useNavigate();
  const t = useTranslator();
  const sessionDetails: SessionDetails = useOutletContext();
  const serviceAdminMap: Map<string, ServiceAccessInfo> = new Map();
  const userSessionInfo = sessionDetails.userSessionInfo;

  if (userSessionInfo.responseCode === 200) {
    userSessionInfo.servicesAccessInfoList.forEach((accessInfo) => {
      // The Service Admin Map will map the service name with its admin Type and operations.
      // The Service Admin Map will be used by operations component to display various operations.
      serviceAdminMap.set(accessInfo.serviceName, accessInfo);
    });
  }

  const navigateToOperations = (serviceName: string) => {
    const operations = serviceAdminMap.get(serviceName)?.operations;
    navigate(`name_${serviceName}/operations`);
    localStorage.setItem("operations", JSON.stringify(operations));
    return;
  };

  const getButtonName = (serviceName: string) => {
    return capitalizeFirstLetter(serviceName);
  };

  return (
    <SpaceBetween size={"xs"} alignItems={"center"}>
      <SelectPage
        headerTitle={t.translate("service_component_Header")}
        data={Array.from(serviceAdminMap.keys())}
        buttonOnClick={navigateToOperations}
        buttonNameFn={getButtonName}
      />
    </SpaceBetween>
  );
};
export default Service;
