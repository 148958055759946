import * as React from 'react';
import {Container} from "@amzn/awsui-components-react/polaris";
import {Box, SpaceBetween} from "@amzn/awsui-components-react";
import {useTranslator} from "../../../localization/Translator";



interface ShowTempPinProps {
    tempPin: string
    tempPinExpiry: string
}

const ShowTempPin = (props: ShowTempPinProps) => {
    const t = useTranslator();


    return (
        <Container>
            <SpaceBetween direction="vertical" size="s">
              <Box fontSize="body-m" fontWeight="bold">{t.translate("temporary_pin_message", {data: props.tempPin})}</Box>
              <Box fontSize="body-m">{t.translate("temporary_pin_guidance", {data: props.tempPinExpiry})}</Box>
            </SpaceBetween>
        </Container>
    )
}

export default ShowTempPin;