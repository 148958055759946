import * as React from 'react';
import {Box, Container, SpaceBetween} from "@amzn/awsui-components-react";
import {PEOPLE_BUS_EMPLOYEE_STATUS_FILTER} from "../../module/AuthAdminModule";
import {getTimeZoneAndFormat} from "../../util/helperUtil";
import {useTranslator} from "../../localization/Translator";


const DisplayAssociateDetails = (props: any) => {
    const t  = useTranslator();

    const translateAssociateData = (header: string, value: any) => {
        if (value === null) {
            return t.translate(header, {data: "N/A"});
        }

        switch (header) {
            case "associate_details_employee_status":
                if (PEOPLE_BUS_EMPLOYEE_STATUS_FILTER.has(value)) {
                    value = PEOPLE_BUS_EMPLOYEE_STATUS_FILTER.get(value);
                } else {
                    value = "Unknown";
                }
                break;
            case "associate_details_id_source":
                if (value === "PAPI") {
                    value += " (PeopleSoft)"
                }
                break;
            case "associate_details_created_at":
                value = new Date(value).toLocaleString() + getTimeZoneAndFormat(value);
                break;
            case "associate_details_last_login_at":
                value = new Date(value).toLocaleString()  + getTimeZoneAndFormat(value);
                break;
        }

        return t.translate(header, {data: value});
    }


    return (
        <Container fitHeight
                   header={<h1 style={{textAlign: "center"}}>{t.translate("associate_details_header")}</h1>}
                   variant="stacked"
        >
            <SpaceBetween direction="vertical" size="s">
                <img
                    src={`https://badgephotos.corp.amazon.com/?login=${props.data.userId}`}
                    alt="associate badge"
                />
                <Box fontSize="body-m" fontWeight="bold">{translateAssociateData("associate_details_id", props.data.userId)}</Box>
                <Box fontSize="body-m" fontWeight="bold">{translateAssociateData("associate_details_id_source", props.data.userIdentitySource)}</Box>
                <Box fontSize="body-m" fontWeight="bold">{translateAssociateData("associate_details_user_status", props.data.userStatus)}</Box>
                <Box fontSize="body-m" fontWeight="bold">{translateAssociateData("associate_details_employee_status", props.data.employeeStatus)}</Box>
                <Box fontSize="body-m" fontWeight="bold">{translateAssociateData("associate_details_employee_location", props.data.employeeLocationId)}</Box>
                <Box fontSize="body-m" fontWeight="bold">{translateAssociateData("associate_details_created_at", props.data.userCreatedAt)}</Box>
                <Box fontSize="body-m" fontWeight="bold">{translateAssociateData("associate_details_last_login_at", props.data.userLastLoginAt)}</Box>
            </SpaceBetween>
        </Container>
    )
}

export default DisplayAssociateDetails;