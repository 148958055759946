import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import afZaTranslation from "../translations/AuthAdmin-af-ZA.puff.json";
import arAeTranslation from "../translations/AuthAdmin-ar-AE.puff.json";
import arEgTranslation from "../translations/AuthAdmin-ar-EG.puff.json";
import arSaTranslation from "../translations/AuthAdmin-ar-SA.puff.json";
import bgBgTranslation from "../translations/AuthAdmin-bg-BG.puff.json";
import bnInTranslation from "../translations/AuthAdmin-bn-IN.puff.json";
import csCzTranslation from "../translations/AuthAdmin-cs-CZ.puff.json";
import deDeTranslation from "../translations/AuthAdmin-de-DE.puff.json";
import enAeTranslation from "../translations/AuthAdmin-en-AE.puff.json";
import enAuTranslation from "../translations/AuthAdmin-en-AU.puff.json";
import enCaTranslation from "../translations/AuthAdmin-en-CA.puff.json";
import enEgTranslation from "../translations/AuthAdmin-en-EG.puff.json";
import enGbTranslation from "../translations/AuthAdmin-en-GB.puff.json";
import enInTranslation from "../translations/AuthAdmin-en-IN.puff.json";
import enSaTranslation from "../translations/AuthAdmin-en-SA.puff.json";
import enSgTranslation from "../translations/AuthAdmin-en-SG.puff.json";
import enUsTranslation from "../translations/AuthAdmin-en-US.puff.json";
import esArTranslation from "../translations/AuthAdmin-es-AR.puff.json";
import esClTranslation from "../translations/AuthAdmin-es-CL.puff.json";
import esCoTranslation from "../translations/AuthAdmin-es-CO.puff.json";
import esEsTranslation from "../translations/AuthAdmin-es-ES.puff.json";
import esMxTranslation from "../translations/AuthAdmin-es-MX.puff.json";
import esUsTranslation from "../translations/AuthAdmin-es-US.puff.json";
import faAfTranslation from "../translations/AuthAdmin-fa-AF.puff.json";
import frCaTranslation from "../translations/AuthAdmin-fr-CA.puff.json";
import frFrTranslation from "../translations/AuthAdmin-fr-FR.puff.json";
import guInTranslation from "../translations/AuthAdmin-gu-IN.puff.json";
import haNgTranslation from "../translations/AuthAdmin-ha-NG.puff.json";
import heIlTranslation from "../translations/AuthAdmin-he-IL.puff.json";
import hiInTranslation from "../translations/AuthAdmin-hi-IN.puff.json";
import htHtTranslation from "../translations/AuthAdmin-ht-HT.puff.json";
import huHuTranslation from "../translations/AuthAdmin-hu-HU.puff.json";
import idIdTranslation from "../translations/AuthAdmin-id-ID.puff.json";
import igNgTranslation from "../translations/AuthAdmin-ig-NG.puff.json";
import itItTranslation from "../translations/AuthAdmin-it-IT.puff.json";
import jaJpTranslation from "../translations/AuthAdmin-ja-JP.puff.json";
import knInTranslation from "../translations/AuthAdmin-kn-IN.puff.json";
import koKrTranslation from "../translations/AuthAdmin-ko-KR.puff.json";
import ltLtTranslation from "../translations/AuthAdmin-lt-LT.puff.json";
import lvLvTranslation from "../translations/AuthAdmin-lv-LV.puff.json";
import mlInTranslation from "../translations/AuthAdmin-ml-IN.puff.json";
import mrInTranslation from "../translations/AuthAdmin-mr-IN.puff.json";
import myMmTranslation from "../translations/AuthAdmin-my-MM.puff.json";
import neNpTranslation from "../translations/AuthAdmin-ne-NP.puff.json";
import nlNlTranslation from "../translations/AuthAdmin-nl-NL.puff.json";
import paInTranslation from "../translations/AuthAdmin-pa-IN.puff.json";
import plPlTranslation from "../translations/AuthAdmin-pl-PL.puff.json";
import psAfTranslation from "../translations/AuthAdmin-ps-AF.puff.json";
import ptBrTranslation from "../translations/AuthAdmin-pt-BR.puff.json";
import ptPtTranslation from "../translations/AuthAdmin-pt-PT.puff.json";
import roRoTranslation from "../translations/AuthAdmin-ro-RO.puff.json";
import ruRuTranslation from "../translations/AuthAdmin-ru-RU.puff.json";
import skSkTranslation from "../translations/AuthAdmin-sk-SK.puff.json";
import slSiTranslation from "../translations/AuthAdmin-sl-SI.puff.json";
import soSoTranslation from "../translations/AuthAdmin-so-SO.puff.json";
import svSeTranslation from "../translations/AuthAdmin-sv-SE.puff.json";
import taInTranslation from "../translations/AuthAdmin-ta-IN.puff.json";
import teInTranslation from "../translations/AuthAdmin-te-IN.puff.json";
import tlPhTranslation from "../translations/AuthAdmin-tl-PH.puff.json";
import trTrTranslation from "../translations/AuthAdmin-tr-TR.puff.json";
import ukUaTranslation from "../translations/AuthAdmin-uk-UA.puff.json";
import urPkTranslation from "../translations/AuthAdmin-ur-PK.puff.json";
import viVnTranslation from "../translations/AuthAdmin-vi-VN.puff.json";
import xhZaTranslation from "../translations/AuthAdmin-xh-ZA.puff.json";
import yoNgTranslation from "../translations/AuthAdmin-yo-NG.puff.json";
import zhCnTranslation from "../translations/AuthAdmin-zh-CN.puff.json";
import zhTwTranslation from "../translations/AuthAdmin-zh-TW.puff.json";
import zuZaTranslation from "../translations/AuthAdmin-zu-ZA.puff.json";
i18n.use(XHR)
    .use(LanguageDetector)
    .init({
        debug: false, // to disable debugging by i18n on console
        lng: "en-US", // default locale
        fallbackLng: "en-US", // fallback locale
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        resources: {
            "af-ZA": {translations: afZaTranslation.resources},
            "ar-AE": {translations: arAeTranslation.resources},
            "ar-EG": {translations: arEgTranslation.resources},
            "ar-SA": {translations: arSaTranslation.resources},
            "bg-BG": {translations: bgBgTranslation.resources},
            "bn-IN": {translations: bnInTranslation.resources},
            "cs-CZ": {translations: csCzTranslation.resources},
            "de-DE": {translations: deDeTranslation.resources},
            "en-AE": {translations: enAeTranslation.resources},
            "en-AU": {translations: enAuTranslation.resources},
            "en-CA": {translations: enCaTranslation.resources},
            "en-EG": {translations: enEgTranslation.resources},
            "en-GB": {translations: enGbTranslation.resources},
            "en-IN": {translations: enInTranslation.resources},
            "en-SA": {translations: enSaTranslation.resources},
            "en-SG": {translations: enSgTranslation.resources},
            "en-US": {translations: enUsTranslation.resources},
            "es-AR": {translations: esArTranslation.resources},
            "es-CL": {translations: esClTranslation.resources},
            "es-CO": {translations: esCoTranslation.resources},
            "es-ES": {translations: esEsTranslation.resources},
            "es-MX": {translations: esMxTranslation.resources},
            "es-US": {translations: esUsTranslation.resources},
            "fa-AF": {translations: faAfTranslation.resources},
            "fr-CA": {translations: frCaTranslation.resources},
            "fr-FR": {translations: frFrTranslation.resources},
            "gu-IN": {translations: guInTranslation.resources},
            "ha-NG": {translations: haNgTranslation.resources},
            "he-IL": {translations: heIlTranslation.resources},
            "hi-IN": {translations: hiInTranslation.resources},
            "ht-HT": {translations: htHtTranslation.resources},
            "hu-HU": {translations: huHuTranslation.resources},
            "id-ID": {translations: idIdTranslation.resources},
            "ig-NG": {translations: igNgTranslation.resources},
            "it-IT": {translations: itItTranslation.resources},
            "ja-JP": {translations: jaJpTranslation.resources},
            "kn-IN": {translations: knInTranslation.resources},
            "ko-KR": {translations: koKrTranslation.resources},
            "lt-LT": {translations: ltLtTranslation.resources},
            "lv-LV": {translations: lvLvTranslation.resources},
            "ml-IN": {translations: mlInTranslation.resources},
            "mr-IN": {translations: mrInTranslation.resources},
            "my-MM": {translations: myMmTranslation.resources},
            "ne-NP": {translations: neNpTranslation.resources},
            "nl-NL": {translations: nlNlTranslation.resources},
            "pa-IN": {translations: paInTranslation.resources},
            "pl-PL": {translations: plPlTranslation.resources},
            "ps-AF": {translations: psAfTranslation.resources},
            "pt-BR": {translations: ptBrTranslation.resources},
            "pt-PT": {translations: ptPtTranslation.resources},
            "ro-RO": {translations: roRoTranslation.resources},
            "ru-RU": {translations: ruRuTranslation.resources},
            "sk-SK": {translations: skSkTranslation.resources},
            "sl-SI": {translations: slSiTranslation.resources},
            "so-SO": {translations: soSoTranslation.resources},
            "sv-SE": {translations: svSeTranslation.resources},
            "ta-IN": {translations: taInTranslation.resources},
            "te-IN": {translations: teInTranslation.resources},
            "tl-PH": {translations: tlPhTranslation.resources},
            "tr-TR": {translations: trTrTranslation.resources},
            "uk-UA": {translations: ukUaTranslation.resources},
            "ur-PK": {translations: urPkTranslation.resources},
            "vi-VN": {translations: viVnTranslation.resources},
            "xh-ZA": {translations: xhZaTranslation.resources},
            "yo-NG": {translations: yoNgTranslation.resources},
            "zh-CN": {translations: zhCnTranslation.resources},
            "zh-TW": {translations: zhTwTranslation.resources},
            "zu-ZA": {translations: zuZaTranslation.resources},
        },
        ns: ["translations"],
        defaultNS: "translations"
    });
export default i18n;