import * as React from 'react';
import ContentHeader from './ContentHeader';
import Button from '@amzn/awsui-components-react/polaris/button';
import { SpaceBetween } from '@amzn/awsui-components-react';

interface SelectPageProps {
    headerTitle: string;
    data: string[];
    buttonOnClick: any;
    buttonName?: string;
    buttonNameFn?: any;
}

const SelectPage = (props: SelectPageProps) => {
    return (
        <SpaceBetween size={"xl"} alignItems={'center'}>
            <ContentHeader title={props.headerTitle}/>
                {
                    props.data.map((name, index) => {
                        return (
                            <div key={index} style={{width: 250, marginBottom: 5}}>
                                <Button variant="primary" fullWidth onClick={() => props.buttonOnClick(name)}>
                                    {props.buttonNameFn(name)}
                                </Button>
                            </div>
                        );
                    })
                }
        </SpaceBetween>
    );
}

export default SelectPage;