import Spinner from "@amzn/awsui-components-react/polaris/spinner";

const Loading = () => {
  return (
    <div className="center">
      <Spinner size={"large"} variant={"disabled"} />
    </div>
  );
};

export default Loading;
