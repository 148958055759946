import { useTranslation } from 'react-i18next';

// Custom hook for fetching translations
export const useTranslator = () => {
  // Adding namespace as translations
  const { t } = useTranslation(["translations"], );

  // Function to get translated value
  const translate = (key: string, variables?: { [key: string]: any }) => {
    let jsonObject = t(key, { returnObjects: true });
    const jsonStringify = JSON.stringify(jsonObject);
    const jsonValue = JSON.parse(jsonStringify);

    let translatedString = jsonValue.value;
    // updating the variable interpolation strings
    if (variables) {
      for (const [variable, value] of Object.entries(variables)) {
        translatedString = translatedString.replace(`{{${variable}}}`, value);
      }
    }
    return translatedString;
  };
  return { translate };
};