import {SpaceBetween} from '@amzn/awsui-components-react';
import ResponsePage, {ResponsePageType} from '../component/common/ResponsePage';
import * as React from 'react';
import HeaderLayout from './HeaderLayout';
import {useTranslator} from "../localization/Translator";

const NotFoundLayout = () => {
  const t = useTranslator();
  return (
    <header>
      <SpaceBetween size={"xxl"}>
        <HeaderLayout />
        <ResponsePage
          message={t.translate("page_not_exist_message")}
          headerTitle={t.translate("page_not_exist_title")}
          pageType={ResponsePageType.INFORMATION}
        />
      </SpaceBetween>
    </header>
  );
}

export default NotFoundLayout;