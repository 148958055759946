import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import '@amzn/awsui-global-styles/polaris.css';
import App from './App';
import {I18nextProvider} from 'react-i18next';
import I18n from './localization/i18n.js';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={I18n}>
      <App/>
    </I18nextProvider>
  </React.StrictMode>
);
