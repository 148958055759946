import {Trans} from 'react-i18next';
import {Alert, AlertProps} from '@amzn/awsui-components-react';
import * as React from 'react';
import {ReactNode} from "react";
import {useTranslator} from "../../localization/Translator";

export enum NotificationType {
  ERROR, INFO, SUCCESS, WARNING
}

const NOTIFICATION_ALERT_PROPS_MAP = new Map<NotificationType, AlertProps>([
  [NotificationType.ERROR, {statusIconAriaLabel: 'Error', type: 'error'}],
  [NotificationType.INFO, {statusIconAriaLabel: 'Info', type: 'info'}],
  [NotificationType.SUCCESS, {statusIconAriaLabel: 'Success', type: 'success'}],
  [NotificationType.WARNING, {statusIconAriaLabel: 'Warning', type: 'warning'}]
]);

interface NotificationProps {
  notificationType: NotificationType;
  message?: string;
  header?: string | ReactNode;
  headerI18nKey?: string;
  headerI18nKeyVariables?: { [key: string]: any };
  messageI18nKey?: string;
  messageI18nKeyVariables?: { [key: string]: any };
}

const Notification = (props: NotificationProps) => {
  const t = useTranslator();
  const message = props.messageI18nKey ? t.translate(props.messageI18nKey, props.messageI18nKeyVariables) : props.message;
  const header = props.headerI18nKey ? t.translate(props.headerI18nKey, props.headerI18nKeyVariables) : props.header;
  const alertProps = NOTIFICATION_ALERT_PROPS_MAP.get(props.notificationType);
  return (
    <Alert statusIconAriaLabel={alertProps?.statusIconAriaLabel}
           type={alertProps?.type}
           header={header}>
      <Trans>{message}</Trans>
    </Alert>
  )
}
export default Notification;