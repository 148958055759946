import * as React from 'react';
import {FormEvent, useState} from 'react';
import {NavigateFunction, useLocation} from 'react-router-dom';
import Notification, {NotificationType} from "../../common/Notification";
import {
  currentApiDomain,
  EMPTY_STRING,
  EMPTY_STRING_ARRAY,
  getServiceNameFromLocation,
  isAllowedAsCommaSeparatedInput,
  isEmptyString,
  MAX_SITE_IDS_FOR_REPORT,
  validateSiteId
} from "../../../util/helperUtil";
import {
  Exception,
  GetSiteReportResponse,
  HOME_PAGE_URL,
  INTERNAL_SERVER_ERROR_CODE_ONE,
  RECOGNIZED_RESPONSE_STATUS,
  Report,
} from "../../../module/AuthAdminModule";
import Link from "@amzn/awsui-components-react/polaris/link";
import ErrorNotification from "../../common/ErrorNotification";
import {ColumnLayout, Form, FormField, Input, SpaceBetween} from '@amzn/awsui-components-react';
import FormActions from '../../common/FormActions';
import {useTranslator} from '../../../localization/Translator';
import {useNavigate} from 'react-router';
import {Trans} from 'react-i18next';
import Button from '@amzn/awsui-components-react/polaris/button';

const GenerateSiteWiseReport = () => {
  const t = useTranslator();
  const navigate: NavigateFunction = useNavigate();
  /****  Constants  *****/
  const OPERATIONS_URL: string = "/services/name_maask/operations";
  const GET_SITE_REPORT = `${currentApiDomain}/GetSiteReport?`;

  /****  States   *****/
  const location = useLocation();
  const serviceName = getServiceNameFromLocation(location);

  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [inputValidationError, setInputValidationError] =
    React.useState(EMPTY_STRING);
  const [isLoading, setIsLoading] = React.useState(false);
  const [siteIdCommaSeparatedString, setSiteIdCommaSeparatedString] =
    React.useState(EMPTY_STRING);
  const [siteIdList, setSiteIdList] = React.useState(EMPTY_STRING_ARRAY);
  const [isInputInvalid, setIsInputInvalid] = React.useState(true);
  const [notifications, setNotifications] = useState([{
    type: NotificationType.INFO, header: "site_report_generation_info_header", message: "site_report_generation_info",
    messageVariable: {}, headerVariable: {}
  }]);

  const [reportList, setReportList] = React.useState([]);

  const validateSiteIdString = (commaSeparatedSiteIds: string) => {
    if (isEmptyString(commaSeparatedSiteIds)) {
      setInputValidationError(t.translate("error_message_required_field"));
      return false;
    }
    if (!isAllowedAsCommaSeparatedInput(commaSeparatedSiteIds)) {
      setInputValidationError(t.translate("input_form_invalid_comma_separated_character_error_message"));
      return false;
    }
    return true;
  }

  const validateSiteIdListSize = (siteIdList: string[]) => {
    if (siteIdList.length > MAX_SITE_IDS_FOR_REPORT) {
      setInputValidationError(t.translate("input_form_invalid_number_of_site_ids_for_site_report",
        {maxSites: MAX_SITE_IDS_FOR_REPORT}));
      return false;
    }
    const set = new Set(siteIdList);
    if (set.size !== siteIdList.length) {
      setInputValidationError(t.translate("input_form_contains_duplicate_site_ids_for_site_report"));
      return false;
    }
    return true;
  }

  const onChangeFn = (value: string) => {
    setInputValidationError(EMPTY_STRING);
    let isValid = validateSiteIdString(value);
    value = value.toUpperCase() || "";
    setSiteIdCommaSeparatedString(value);
    if (!isValid) {
      setIsInputInvalid(true);
      return;
    }
    const siteIdList = value.split(',').map(item => item.trim()).filter(item => item);
    siteIdList.forEach(siteId => {
      isValid = isValid && validateSiteId(siteId, setInputValidationError, t);
    });
    isValid = isValid && validateSiteIdListSize(siteIdList);
    if (!isValid) {
      setIsInputInvalid(true);
      return;
    }
    setIsInputInvalid(false);
    setSiteIdList(siteIdList);
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (setSiteIdList.length === 0) {
      console.error("Empty list of site ids.")
      return;
    }
    setErrorCode(EMPTY_STRING);
    setIsLoading(true);
    const baseUrl = `${GET_SITE_REPORT}serviceName=${encodeURIComponent(serviceName)}`;
    const url = new URL(baseUrl);
    const values = siteIdList.join(',');
    url.searchParams.append('siteIds', values);
    fetch(url, {
      method: "get",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 401) {
          // redirect to authenticate
          window.location.replace(currentApiDomain + "/Authorize");
          return res.json();
        }
        if (RECOGNIZED_RESPONSE_STATUS.includes(res.status)) {
          return res.json();
        }
        throw new Error("Unrecognized response status " + JSON.stringify(res));
      })
      .then((res: any) => {
        // Success:200
        if (res.hasOwnProperty("responseCode")) {
          res = res as GetSiteReportResponse;
          if (res.responseCode === 200) {
            const reports = res.reportList;
            setReportList(reports);
            // success reports
            const successSites = reports.filter((report: Report) => {
              return report.isSuccess;
            }).map((report: Report) => {
              return report.siteId.toUpperCase();
            });
            console.log("success", successSites);
            // failures not_authorized_for_site, invalid_input_report_unavailable
            const failedSites = reports.filter((report: Report) => {
              return !report.isSuccess;
            }).map((report: Report) => {
              return report.siteId.toUpperCase();
            });
            console.log("failed", failedSites);

            const failedSitesNotAuthorized = reports.filter((report: Report) => {
              return !report.isSuccess && report.message === "operation_not_permitted";
            }).map((report: Report) => {
              return report.siteId.toUpperCase();
            });
            console.log("failedSitesNotAuthorized ", failedSitesNotAuthorized);

            const failedSitesNotAvailable = reports.filter((report: Report) => {
              return !report.isSuccess && report.message === "invalid_input_report_unavailable";
            }).map((report: Report) => {
              return report.siteId.toUpperCase();
            });
            console.log("failedSitesNotAvailable", failedSitesNotAvailable);

            const notifications = [];
            if (successSites.length !== 0) {
              notifications.push({
                type: NotificationType.SUCCESS,
                header: "site_report_generation_success_response_header_message",
                message: "site_report_generation_success_response_message",
                messageVariable: {siteIds: successSites}, headerVariable: {}
              });
            }
            if (failedSitesNotAvailable.length !== 0) {
              const type = successSites.length !== 0 ?
                NotificationType.WARNING : NotificationType.ERROR;
              const header = "site_report_unAvailable_for_given_siteID_response_header_message";
              const message = "site_report_unAvailable_for_given_siteID_response_message";
              notifications.push({
                type: type,
                header: header,
                message: message,
                messageVariable: {}, headerVariable: {siteIds: failedSitesNotAvailable}
              });
            }
            if (failedSitesNotAuthorized.length !== 0) {
              const type = successSites.length !== 0 ?
                  NotificationType.WARNING : NotificationType.ERROR;
              const header = "site_report_generation_fail_response_header_message";
              const message = "forbidden_error_message_site_report";
              notifications.push({
                type: type,
                header: header,
                message: message,
                messageVariable: {}, headerVariable: {siteIds: failedSitesNotAuthorized}
              });
            }
            setNotifications(notifications);
            return;
          }
          setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
          return;
        }
        // Exception from backend
        if (res.hasOwnProperty("errorId")) {
          console.log(siteIdList);
          res = res as Exception;
          console.error("Unsuccessful API call", res.message, res);
          if (res.errorId === "40303") {
            const sites = siteIdList.map((siteId: string) => {
              return siteId.toUpperCase();
            });
            setNotifications([{
              type: NotificationType.ERROR,
              header: "site_report_generation_fail_response_header_message",
              message: "forbidden_error_message_site_report",
              messageVariable: {}, headerVariable: {siteIds: sites}
            }]);
            return;
          }
          setErrorCode(res.errorId);
          return;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
        console.error(err.message);
      });
  };

  const responsePageOnClick = () => {
    navigate(HOME_PAGE_URL);
  };

  const locationDescription = (
    <Trans>{t.translate("location_form_site_report_field_description")}</Trans>
  );

  const formPage = (
    <SpaceBetween size={"xxl"} alignItems={"center"}>
      {
        notifications.map(notification => {
          return <Notification notificationType={notification.type}
                               headerI18nKey={notification.header}
                               messageI18nKey={notification.message}/>
        })
      }
      <form style={{}} onSubmit={(e) => handleSubmit(e)}>
        <Form
          className={"width-flex"}
          actions={
            <FormActions
              cancelButtonName={t.translate("cancel_button_name")}
              submitButtonName={t.translate("submit_button_name")}
              cancelOnClick={() => navigate(OPERATIONS_URL)}
              isLoading={isLoading}
              isInputInvalid={isInputInvalid}
            />
          }>
          <SpaceBetween direction="vertical" size="l">
            <FormField
              stretch
              label={t.translate("site_id_form_field_label")}
              errorText={inputValidationError}
              description={locationDescription}>
              <Input value={siteIdCommaSeparatedString} onChange={(event) => onChangeFn(event.detail.value)}/>
            </FormField>
          </SpaceBetween>
        </Form>
      </form>
    </SpaceBetween>
  );
  const errorResponsePage = <SpaceBetween size={"xxl"} alignItems={"center"}>
    {errorCode && <ErrorNotification errorCode={errorCode}/>}
    {
      notifications.map(notification => {
        return <Notification notificationType={notification.type}
                             headerI18nKey={notification.header}
                             messageI18nKey={notification.message}
                             messageI18nKeyVariables={notification.messageVariable}
                             headerI18nKeyVariables={notification.headerVariable}/>
      })
    }
    <Button
      formAction="none"
      variant="primary"
      onClick={responsePageOnClick}>
      {t.translate("back_button_name")}
    </Button>
  </SpaceBetween>

  if (errorCode || (notifications.length === 1 && notifications[0].type === NotificationType.ERROR)) {
    return errorResponsePage;
  }

  const resultPage =
    <SpaceBetween size={"xxl"} alignItems={"center"}>
      {
        notifications.map(notification => {
          return <Notification notificationType={notification.type}
                               headerI18nKey={notification.header}
                               messageI18nKey={notification.message}
                               messageI18nKeyVariables={notification.messageVariable}
                               headerI18nKeyVariables={notification.headerVariable}/>
        })
      }
      <ColumnLayout columns={1} borders="horizontal" minColumnWidth={250} className={"margin-bottom-50"}>
        {
          reportList.filter((responseUrl: Report) => responseUrl.isSuccess)
            .map((responseUrl: Report) => {
              return (
                <Link fontSize={"heading-m"}
                      href={responseUrl.reportUrl}>{t.translate("site_report_generation_link_message",
                  {"siteId": responseUrl.siteId.toUpperCase(), "lastModifiedAt":
                        responseUrl.lastModifiedAt.split('T')[0]})}</Link>
              )
            })
        }
      </ColumnLayout>
      <Button
        formAction="none"
        variant="primary"
        onClick={responsePageOnClick}>
        {t.translate("back_button_name")}
      </Button>
    </SpaceBetween>

  return (reportList && reportList.length !== 0) ? resultPage : formPage;

};
export default GenerateSiteWiseReport;