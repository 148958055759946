import * as React from "react";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import { useLocation } from "react-router-dom";
import { BreadcrumbGroupProps } from "@amzn/awsui-components-react/uxdg";
import { useTranslator } from "../../localization/Translator";

const EMPTY_CRUMB: BreadcrumbGroupProps.Item = {
  text: "",
  href: "",
};
const BREADCRUMB_KEY_PREFIX: string = "breadcrumb_pathname_";
const PARAMETER_PREFIX: string = "name_";
const EMPTY_STRING: string = "";
const PATHNAME_SEPARATOR: string = "/";

function Breadcrumb() {
  const t = useTranslator();
  const location = useLocation();
  let currentLink = "";

  const crumbs = location.pathname
    .split(PATHNAME_SEPARATOR)
    .filter((crumb) => crumb !== EMPTY_STRING)
    .map((crumb) => {
      // form the link for href
      currentLink += `/${crumb}`;

      // We want to ignore paths starts with name_. These are treated as values and do not have a page.
      // We could not have filtered this out in 'filter' as we want current link (above) to be formed here for each crumb.
      if (crumb.toLowerCase().startsWith(PARAMETER_PREFIX)) return EMPTY_CRUMB;

      const text = t.translate(BREADCRUMB_KEY_PREFIX + crumb);

      return {
        text: text,
        href: currentLink.slice(),
      };
    })
    .filter((crumb) => crumb !== EMPTY_CRUMB);

  const EMPTY = <></>;
  const crumbsPage = <BreadcrumbGroup items={crumbs} ariaLabel="Breadcrumbs" />;

  return crumbs.length === 1 ? EMPTY : crumbsPage;
}

export default Breadcrumb;
